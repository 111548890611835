import React, { useState, useEffect } from 'react'
import {
  Head,
  Nav,
  Sales,
  Footer,
  Timeline,
  Stores,
  Units,
  Map,
  Form,
  SlickHome,
} from '~/components'
import { Link, PageProps } from 'gatsby'
import * as st from '~/assets/styl/Home.module.styl'
import { scroller, animateScroll } from 'react-scroll'
import { useBreakpoints } from '~/hooks'
import cn from 'classnames'
import shell from '~/assets/svg/svg-brands-shell.svg'
import select from '~/assets/svg/svg-brands-shell-select.svg'
import ipiranga from '~/assets/svg/svg-brands-ipiranga.svg'
import ampm from '~/assets/svg/svg-brands-ampm.svg'
import petrobras from '~/assets/svg/svg-brands-petrobras.svg'
import brmania from '~/assets/svg/svg-brands-brmania.svg'
import rodoil from '~/assets/svg/svg-brands-rodoil.svg'
import bauerExpress from '~/assets/svg/svg-logo-bauer-express.svg'
import bauerPostos from '~/assets/svg/svg-logo-bauer-postos.svg'
import bauerTurismo from '~/assets/svg/svg-logo-bauer-turismo.svg'
import bannerComercial from '~/assets/img/banner-comercial-desktop.jpg'
import bannerComercialMobile from '~/assets/img/banner-comercial-mobile.jpg'

const Home = () => {
  const breakpoint = useBreakpoints()

  useEffect(() => {
    if (window.location.hash) {
      document.body.style.cursor = 'wait'
      const f = setInterval(() => {
        const scrollY = (
          document.querySelector(window.location.hash) as HTMLElement
        )?.offsetTop
        const viewsCount = (document.querySelector('.views') as HTMLElement)
          .childElementCount
        if (viewsCount === 1 && document.body.offsetHeight >= scrollY) {
          scroller.scrollTo(window.location.hash.split('#')[1], {
            smooth: true,
            duration: 1250,
            offset: -100,
          })
          document.body.style.cursor = null
          clearInterval(f)
        }
      }, 50)
      if (window.location.hash === '#trabalhe-conosco') setTab(true)
    }
    ;(window as any).setTab = setTab
    return () => ((window as any).setTab = null)
  }, [])

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }

  const [tab, setTab] = useState(false)

  return (
    <>
      <Head />
      <main id="topo">
        <section className={st.banner}>
          <h1>O nosso melhor, sempre.</h1>
        </section>
        <Sales />

        <SlickHome />

        <div className={cn(st.content1364, st.sobre)} id="sobre">
          <div className={st.equalHeightGridTop}>
            <section className={st.grid50}>
              <h2>Nossa história</h2>
              <p style={{ marginBottom: 20 }}>
                Nascemos em 2019 com um único objetivo: oferecer serviços de
                qualidade, com produtos certificados, em pontos estratégicos.
                Desde então, vimos nosso crescimento ser rápido e exponencial.
                Nosso intuito é fortalecer o vínculo com os clientes e entregar
                nosso melhor em todas as atividades.
              </p>
              <p>
                A Bauer Postos é uma empresa do Grupo Bauer, com mais de 22 anos
                de experiência atuando na área de turismo e logística.
              </p>
            </section>
          </div>
        </div>
        <div className={st.timeline}>
          <div className={st.content1364}>
            <Timeline />
          </div>
        </div>

        <Stores />

        <div className={cn(st.content1364, st.stations)}>
          <div className={st.equalHeightGridTop}>
            <div className={st.grid50}>
              <h2>Bauer Postos</h2>
              <p>
                Nossa rede contém 9 unidades espalhadas por 6 cidades. Todos
                nossos postos foram pensados em regiões estratégicas,
                localizados em rodovias onde facilitam o acesso trazendo aos
                clientes mais conforto e agilidade.
              </p>
            </div>
            <div className={st.grid50}>
              <div className={st.yellowBox}>
                <p>Área para Happy Hour</p>
              </div>
              <div className={st.yellowBox}>
                <p>Postos de rodovia com área do motorista</p>
              </div>
            </div>
          </div>
          <div className={st.equalHeightGridTop}>
            <div className={st.grid100}>
              <div className={st.yellowBox}>
                <p>Conveniência</p>
              </div>
              <div className={st.yellowBox}>
                <p>
                  Estacionamento <span>24 horas*</span>
                </p>
                <small>*Unidade Chapecó Centro e São Lourenço do Oeste</small>
              </div>
              <div className={st.yellowBox}>
                <p>Ambientes especiais</p>
              </div>
            </div>
          </div>
        </div>

        <div className={cn(st.content1364, st.bannerBusinessContact)}>
          <Link to="/contato-comercial">
            <img
              src={
                breakpoint === 'mobile'
                  ? bannerComercialMobile
                  : bannerComercial
              }
              alt="Banner seja um cliente Bauer Postos"
              width={1364}
              height={420}
            />
          </Link>
        </div>

        <Units />

        {/* <div className={st.content1364}>
          <Map />
        </div> */}

        <section className={st.content1364}>
          <h2>Nossas Marcas</h2>
          <ul className={st.brandsList}>
            <li>
              <img src={shell} alt="Logo da Shell" />
            </li>
            <li>
              <img src={select} alt="Logo da Shell Select" />
            </li>
            <li>
              <img src={ipiranga} alt="Logo do Postos Ipiranga" />
            </li>
            <li>
              <img src={ampm} alt="Logo do Am Pm" />
            </li>
            <li>
              <img src={petrobras} alt="Logo da Petrobras" />
            </li>
            <li>
              <img src={brmania} alt="Logo da BR Mania" />
            </li>
            <li>
              <img src={rodoil} alt="Logo da Rodoil" />
            </li>
          </ul>
        </section>

        <div className={st.contatos} id="trabalhe-conosco"></div>
        <div className={st.contatos} id="fale-conosco"></div>
        <div className={st.faleConosco}>
          <section>
            <button className={!tab && st.active} onClick={() => setTab(false)}>
              Fale Conosco
            </button>
            <button className={tab && st.active} onClick={() => setTab(true)}>
              Trabalhe Conosco
            </button>
          </section>
          <span></span>
          {tab === false ? (
            <section>
              <h2>Envie uma mensagem</h2>
              <div className={st.content812}>
                <Form />
              </div>
            </section>
          ) : (
            <section className={st.workUs}>
              <h2>Faça parte do nosso time!</h2>
              <div className={st.content812}>
                <p>
                  Ao enviar o seu currículo você será direcionado&#40;a&#41; a
                  uma nova página, clique em &quot;Cadastrar meu currículo&quot;
                  para prosseguir.
                </p>
                <div>
                  <a
                    href="https://platform.senior.com.br/hcmrs/hcm/curriculo/?tenant=bauerexpress&tenantdomain=bauerexpress#!/vacancies/list"
                    target="_blank"
                    rel="noreferrer"
                    title="Bauer Postos - acessar a página Trabalhe Conosco"
                  >
                    Cadastrar meu currículo
                  </a>
                </div>
              </div>
            </section>
          )}
        </div>

        <section className={cn(st.content897, st.empresas)}>
          <h2>Empresas do Grupo Bauer</h2>
          <ul>
            <li>
              <a
                href="https://www.bauerexpress.com.br/"
                target="_blank"
                rel="noreferrer"
                title="Acessar página da Bauer Express"
              >
                <img src={bauerExpress} alt="Logo da Bauer Express" />
              </a>
            </li>
            <li>
              <Link to="/" onClick={scrollToTop}>
                <img src={bauerPostos} alt="Logo da Bauer Postos" />
              </Link>
            </li>
            {/* <li>
              <a
                href="https://www.bauerturismo.com/"
                target="_blank"
                rel="noreferrer"
                title="Acessar página da Bauer Turismo"
              >
                <img src={bauerTurismo} alt="Logo da Bauer Turismo" />
              </a>
            </li> */}
          </ul>
        </section>
      </main>

      <Footer />
    </>
  )
}

export default Home
